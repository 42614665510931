@import '~toolbox-common/src/assets/css/colors.scss';

.smv-header {
  z-index: 100;
  background-color: white;
  // max-width: 1440px !important;
  padding: 2px 16px;
  border-bottom: 1px solid $smv-background-light;
  transition: 500ms;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;

  nav {
    // 1340px because of the 50px on left & right of the .base-content
    max-width: 1340px !important;
  }

  .nav-item--flag {
    padding: 8px;
    margin-right: 4px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background-color: $smv-background-light;
      border: 1px solid $smv-background;
    }
  }

  &.smv-header--mobilemenu-open {
    height: auto;
    overflow-y: auto;
    max-height: 100%;
    align-items: flex-start;
  }

  &:not(.smv-header--mobilemenu-open) {
    @media (max-width: 991px) {
      .smv-header__collapse {
        display: none;
      }
    }
  }

  @media (min-width: 992px) {
    padding: 15px 50px;
    height: 90px;
    font-size: 0.8rem;
    .dropdown-menu {
      font-size: 0.88rem;
    }
  }
  @media (min-width: 1200px) {
    font-size: 1rem;
    .dropdown-menu {
      font-size: 1rem;
    }
  }
}

.smv-header--shrinked {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  transition: 500ms;

  &:not(.smv-header--mobilemenu-open) {
    height: 65px;
  }
}

.smv-header__projectmenu {
  display: none;
  align-items: center;
  height: 100%;

  .menu-item {
    .nav-link::before {
      background-color: $smv-primary;
    }
  }

  .nav-link.active {
    color: $smv-font-dark !important;
  }

  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
  }
}

.smv-header__projectmenu--active {
  display: flex;
}

.smv-header__menuitem-separator {
  width: 80%;
  height: 1px;
  background-color: $smv-background-light;

  @media (min-width: 992px) {
    width: 1px;
    height: 88%;
    margin: 4px;
    align-self: center;
  }
}

.smv-header__navbar {
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .navbar-close {
    width: 56px;
    height: 40px;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    @media (min-width: 992px) {
      display: none;
    }
  }

  @media (min-width: 992px) {
    height: calc(100% - 4px);
  }
}

.smv-header__collapse {
  @media (min-width: 992px) {
    height: 100%;
    padding-bottom: 0;
  }

  .navbar-nav {
    justify-content: center;
    margin-right: 0;

    @media (min-width: 992px) {
      margin-right: auto;
      height: 100%;
      align-items: center;
    }

    .menu-item {
      justify-content: center;
      display: flex;
      height: 100%;

      .nav-link::before {
        @media (min-width: 992px) {
          bottom: -15px;
        }
      }
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
  }
}

.smv-header__dropdown {
  display: none;

  @media (min-width: 992px) {
    display: block;
  }

  .dropdown-item {
    color: $smv-font;
    &:active {
      background-color: white;
    }
    &:hover {
      background-color: $smv-background-light;
    }
  }

  .dropdown-item {
    &.active {
      background-color: white;
      font-weight: bold;
      &:hover {
        background-color: $smv-background-light;
      }
    }
  }
}

.smv-header__usermenu-mobile {
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-top: 12px;
  border-top: 1px solid $smv-background-light;
  a {
    color: $smv-font;
    &.active {
      text-decoration: underline;
    }
  }

  > * {
    margin: 4px;
  }
  @media (min-width: 992px) {
    display: none;
  }

  .usermenu-mobile__flag {
    padding: 4px 8px;
    margin: 0 4px;
    cursor: pointer;
    
    &--active {
      background: $smv-background-light;
      border: 1px solid $smv-background;
      border-radius: 0.25rem;
    }
  }
  
  .usermenu-mobile__organization {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-headline {
      font-weight: 500;
    }
    
    div {
      padding: 4px;
      
      &.active {
        text-decoration: underline;
      }
    }
  }
}
