@import '~toolbox-common/src/assets/css/colors.scss';

.siteswitch-container {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: 40px 64px;
  border: 1px solid $smv-background-light;
  border-radius: 0.25rem;
  background-color: $smv-white;
  
  h2 {
    font-size: 1.25rem;
  }

  hr {
    width: 64%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.siteswitch {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  width: 100%;
  padding: 12px;
  transition: background-color 200ms ease-in-out, border 200ms ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: $smv-background-light;
    border: 1px solid $smv-background;
  }

  .siteswitch__img-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;
    
    img {
      width: auto;
      max-width: 100%;
      max-height: 28px;
    }
  }
  .siteswitch__label-container {
    width: 50%;
    padding-left: 8px;
  }
}