@import './colors.scss';

$body-color: $smv-font;

$theme-colors: (
  'primary': $smv-primary,
  'secondary': $smv-secondary,
  'grey': $smv-font-light,
  'warning': $smv-warning,
  'danger': $smv-danger
);


@import '~bootstrap/scss/bootstrap';

// overwrite newly added default colors for .btn-grey
.btn-grey, .btn-grey:hover {
  color: white;
}