@import '~toolbox-common/src/assets/css/colors.scss';

.multipurposeslider {
  .multipurposeslider__handle {
    position: absolute;
    margin-left: -15px;
    margin-top: 25px;
    z-index: 2;
    width: 30px;
    height: 30px;
    border: 0;
    text-align: center;
    border-radius: 50%;
    background-color: $smv-secondary;
    color: $smv-font;
    transition: background-color ease-in-out 200ms;
    &:hover {
      cursor: pointer;
      background-color: lighten($smv-secondary, 10%);
      .multipurposeslider__handle-tooltip,
      .multipurposeslider__handle-tooltiptext {
        opacity: 1;
      }
      .multipurposeslider__handle-delete {
        opacity: 1;
      }
    }
  }
  .multipurposeslider__handle-caption {
    font-size: 0.8rem;
    margin-top: -18px;
  }
  .multipurposeslider__track {
    position: absolute;
    height: 10px;
    z-index: 1;
    margin-top: 35px;
    background-color: $smv-secondary-lightest;
    border-radius: 5px;
  }
  .multipurposeslider__track--add-enabled {
    .multipurposeslider__plus-btn {
      position: absolute;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 16px;
      left: calc(50% - 10px);
      border: 1px solid $smv-background-light;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
        background-color: $smv-secondary;
        color: $smv-white;
      }
    }
  }
  .multipurposeslider__track-value {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.8rem;
  }
  .multipurposeslider__track-value--top {
    margin-top: -20px;
  }
  .multipurposeslider__track-value--bottom {
    margin-top: 10px;
    min-width: 35px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .multipurposeslider__slider {
    position: relative;
    width: 100%;
    height: 80px;
  }
  .multipurposeslider__rail {
    position: absolute;
    width: 100%;
    height: 10px;
    margin-top: 35px;
    border-radius: 5px;
    background-color: $smv-background;
  }

  .multipurposeslider__handle-tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted #444;
    margin-left: 22px;
  }

  .multipurposeslider__handle-tooltip .multipurposeslider__handle-tooltiptext {
    width: 60px;
    background-color: #444;
    color: #f0f0f0;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 18px;
    left: 50%;
    margin-left: -41px;
    opacity: 0;
    transition: opacity ease-in-out 200ms;
  }

  .multipurposeslider__handle-tooltip .multipurposeslider__handle-tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #444 transparent transparent transparent;
  }

  .multipurposeslider__handle-delete {
    position: absolute;
    top: 35px;
    width: 40px;
    height: 35px;
    color: $smv-danger;
    background-color: white;
    border: 1px solid #ebebeb;
    opacity: 0;
    transition: opacity ease-in-out 200ms;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    right: -5px;

    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $smv-danger transparent;
    }
  }
}
