@import '~toolbox-common/src/assets/css/colors.scss';

.stone-smv {
  fill: $smv-primary;
}

@mixin stoneFillColor($colorName, $color, $hoverColor) {
  .stone-smv-- {
    &#{$colorName} {
      fill: $color;
    }
  }
}

@each $colorName, $color, $hoverColor in $smv-clr-map {
  @include stoneFillColor($colorName, $color, $hoverColor);
}
