@import '~toolbox-common/src/assets/css/buttons.scss';
@import '~toolbox-common/src/assets/css/icons.scss';
@import '~toolbox-common/src/assets/css/badges.scss';

@import '~toolbox-common/src/assets/css/colors.scss';
@import '~toolbox-common/src/assets/css/links.scss';
@import '~toolbox-common/src/assets/css/texts.scss';
@import '~toolbox-common/src/assets/css/animations.scss';
@import '~toolbox-common/src/assets/css/forms.scss';
@import '~toolbox-common/src/assets/css/dnd-default-styles.scss';

/**
    media width breakpoints
    large:  >= 1024px / max width 1440px
    medium: <= 1024px
    small:  <= 768px
    xsmall: <= 320px / min width 320 px
 */

html {
  scroll-behavior: smooth;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $smv-background-light;
    border-radius: 0px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $smv-background;
    border-radius: 0.5rem;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $smv-background-dark;
  }
}

.base-header {
  z-index: 110;
  background-color: white;
  height: 90px;
  max-width: 1440px !important;
  padding: 0 50px;
  border-bottom: 1px solid $smv-background-light;
  transition: 500ms;
}

.base-header.shrinked {
  height: 65px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  transition: 500ms;
}

.base-content {
  position: relative;
  top: 0;
  max-width: 1440px !important;
  min-height: calc(100vh - 90px);
  padding: 120px 50px 20px 50px;
  hyphens: auto;

  &:not(.mw-100) {
    max-width: 1440px !important;
  }

  &.mw-100 {
    max-width: none !important;
  }
}

.box-shadow {
  box-shadow: 1px 3px 5px $smv-background-light;
}

* {
  font-family: Roboto;
}

@font-face {
  font-family: 'Figtree';
  src: local('Figtree'), url(./assets/fonts/figtree/Figtree-Regular.ttf) format('truetype');
}

#root {
  background-color: $smv-white;
}

.base-header .dropdown-menu {
  padding: 0;

  .dropdown-item {
    display: flex;
    align-items: center;
    height: 50px;
    line-height: 50px;
  }
}

.dropleft.dropdown-hover:hover .dropdown-menu {
  display: block;
  margin-right: 0;
}

/* disable text selection */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.col-transition {
  transition: all 400ms ease;
}

.col-0 {
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  width: 0 !important;
  height: 0 !important;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 0%;
}

.cursor-pointer {
  cursor: pointer;
}

.list-item-sticky {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.top-0 {
  top: 0;
}
