/*.d-flex {*/
/*display: flex!important;*/
/*display: -ms-flexbox!important;*/
/*display: -webkit-flex!important;*/
/*}*/

/* To solve the tooltip overlap issue */
#root {
  opacity: 0.99;
}

.bg-header {
  background-color: #566571;
}

.bg-breadcrump {
  background-color: #a9b3bd;
}

.bg-content {
  background-color: #8393a2;
}

.btn-green-with-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
  background-color: #8cd150;
}

.btn-green-with-outline-light:hover {
  color: #212529;
  border-color: #f8f9fa;
  background-color: #d8efc4;
}

.scroll-y {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.no-scroll {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.pointer {
  cursor: pointer;
}

.fa-background {
  color: white;
  opacity: 0.05;
  font-size: 10rem;
}

.opacity-50 {
  opacity: 0.5;
}

.fa-25 {
  /*font-size: 1rem;*/
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.ms200x200 {
  max-width: 200px !important;
  max-height: 200px !important;
}

.s200x200 {
  width: 200px !important;
  height: 200px !important;
  max-width: 200px !important;
  max-height: 200px !important;
}

.icon-wrapper {
  position: relative;
}

.icon-wrapper button {
  position: absolute;
  top: 2px;
  right: 2px;
  /*font-size: 40px;*/
  /*color: white;*/
}
