@import '~toolbox-common/src/assets/css/colors.scss';

.smv-imprint {
  a {
    color: $smv-secondary;
    &:hover {
      color: $smv-secondary-light;
    }
  }
  .smv-imprint__social {
    display: flex;
    // flex-direction: column;
    font-size: 2.5rem;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    a {
      margin-right: 0.5rem;
      // margin-left: 0.5rem;
      i {
        
        transition: color 250ms ease-in-out;
        
        &.fa-linkedin {
          color: #0077b5;
        }
        &.fa-xing-square {
          color: #026466;
        }
        &:hover {
          color: $smv-primary;
        }
      }
    }
  }
  .smv-imprint__text {
    line-height: 1.75;
  }
}