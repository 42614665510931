@import 'colors.scss';

@mixin button($colorName, $color, $hoverColor) {
  .btn-smv- {
    &#{$colorName} {
      color: white;
      background-color: $color;
      border: 1px solid $color;

      &:hover {
        color: white;
        background-color: $hoverColor;
        border-color: $hoverColor;
      }

      &.active {
        color: $smv-white;
        background-color: $color;
      }

      &:focus {
        box-shadow: none;
      }

      &-outline {
        color: $color;
        background-color: transparent;
        border: 1px solid $color;

        &:hover:not(:disabled) {
          color: white;
          background-color: $hoverColor;
        }

        &.active {
          color: white;
          background-color: $color;
          &:hover {
            background-color: $color;
          }
        }

        &:focus {
          box-shadow: none;
        }

        &.disabled {
          color: $smv-font;
          background-color: $smv-white;
        }
      }
    }
  }
}

@each $colorName, $color, $hoverColor in $smv-clr-map {
  @include button($colorName, $color, $hoverColor);
}
