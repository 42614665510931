@import 'colors.scss';

//@mixin icons($colorName, $color) {
//  .icon-smv- {
//    &#{$colorName} {
//      cursor: pointer;
//      color: $color;
//      transition: color 250ms ease-in-out;
//
//      &:hover{
//        color: $color;
//        background-color: white;
//      }
//
//      &-outline {
//        color: $color;
//        background-color: transparent;
//        border: 1px solid $color;
//
//        &:hover {
//          color: white;
//          background-color: $color;
//        }
//      }
//
//    }
//  }
//}
//
//@each $colorName, $color in $smv-clr-map {
//  @include button($colorName, $color);
//}

.icon-smv-blue {
  cursor: pointer;
  color: $smv-secondary;
  transition: color 250ms ease-in-out;
}

.icon-smv-blue:hover {
  color: $smv-warning;
}

.icon-smv-font-light {
  cursor: pointer;
  color: $smv-font-light;
  transition: color 250ms ease-in-out;
}

.icon-smv-font-light:hover {
  color: $smv-font-light;
}
