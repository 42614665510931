@import '../../assets/css/colors.scss';

.iconprovider--clickable {
  &:hover {
    cursor: pointer;
    svg {
      rect {
        opacity: 0.9;
      }
    }
  }
}

.iconprovider {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.iconprovider__fa {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: $smv-font;
}
.iconprovider__fa--filled {
  height: 20px;
  background-color: $smv-font;
  color: $smv-white;
  width: 34px;
}

.iconprovider_with_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.iconprovider_with_name--clickable {
  &:hover {
    cursor: pointer;
  }
}

.iconprovider_with_name--dragable {
  transition: background-color 180ms ease-in-out;
  &:hover {
    cursor: grab;
    background-color: $smv-background-light;
  }
}

.iconprovider_with_name__name {
  margin-top: 0px;
  margin-bottom: 2px;
  padding-bottom: 2px;
  font-size: 0.75rem;
  line-height: 1;
  max-width: 100%;
  text-align: center;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
