@import 'colors.scss';

@mixin badge($colorName, $color, $hoverColor) {
  .badge-smv- {
    &#{$colorName} {
      color: $smv-white;
      background-color: $color;
      transition: background-color 200ms ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: $hoverColor;
      }

      &-outline {
        color: $color;
        background-color: transparent;
        border: 1px solid $color;
        transition: all 200ms ease-in-out;

        &:hover {
          cursor: pointer;
          background-color: $color;
          color: $smv-white;
        }
      }
    }
  }
}

@each $colorName, $color, $hoverColor in $smv-clr-map {
  @include badge($colorName, $color, $hoverColor);
}
