@import 'colors.scss';

.text-smv {
  color: $smv-font;
}

.text-smv-light {
  color: $smv-font-light;
}

.text-smv-red, .text-smv-danger {
  color: $smv-danger;
  &:hover {
    color: $smv-danger;
  }
}

.text-smv-green, .text-smv-primary {
  color: $smv-primary;
}

.text-smv-orange, .text-smv-warning {
  color: $smv-warning;
}

.text-smv-blue, .text-smv-secondary {
  color: $smv-secondary;
}
