@import './colors.scss';

.draggable-default {
  box-sizing: border-box;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 1px 0 #4c4c4c40;
}

.droppable-default {
  background: $smv-background-light;
  padding: 8px 4px 4px 4px;
  border-radius: 4px;
}

.droppable-default--disabled {
  position: relative;
  
  &:after {
    border-radius: 0.25rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    background-color: $smv-background-light;
    opacity: 0.7;
  }
}