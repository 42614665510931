@import '~toolbox-common/src/assets/css/colors.scss';

.base-footer-container {
  position: relative;
  box-sizing: border-box;
  margin-top: 10px;
  min-height: 65px;
  width: 100%;
  padding: 16px 8px;
  border-top: 1px solid $smv-background-light;
  background-color: $smv-white;
  transition: 2500ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.base-footer {
  box-sizing: border-box;
  height: 100% !important;
  max-width: 1440px !important;
}

.base-footer-container__info {
  padding: 16px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.base-footer-container__links {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 24px;
  a {
    color: $smv-font;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 500;
    &:hover {
      color: $smv-font-light;
      text-decoration: underline;
    }
  }
}

.footer-separator {
  display: none;
  margin-left: 8px;
  margin-right: 8px;
}

.base-footer-container__logo {
  margin-top: 24px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}

.base-footer-container__emoji {
  margin-top: 4px;
}

@media (min-width: 1024px) {
  .base-footer-container {
    padding: 8px 50px;
    flex-direction: row;
    justify-content: space-between;
  }
  .base-footer-container__info {
    padding: 8px;
    margin-top: 0;
  }
  .base-footer-container__links {
    flex-direction: row;
    text-align: center;
    margin-top: 0;
    a {
      margin: 0;
    }
  }
  .footer-separator {
    display: block;
  }
  .base-footer-container__logo {
    margin: 0;
  }
}
