.fade-container {
  position: absolute;
  padding-right: 30px;
  width: 100%;
  max-width: 100%;
}

.size-transition {
  transition: height 400ms linear, width 400ms linear;
}

/**
    ROTATE ANIMATION
 */
.rotate-loop {
  animation: rotateAnimation 4s infinite ease-in-out;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**
    FADE ANIMATION
 */
.fade-appear {
  opacity: 0;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 600ms linear;
}
.fade-enter {
  opacity: 0;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms linear 200ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 600ms linear;
}

/**
    SLIDE ANIMATION
 */
.slide-appear {
  opacity: 0;
}
.slide-appear.slide-appear-active {
  opacity: 1;
  transition: opacity 600ms linear;
}
.slide-enter {
  opacity: 0;
}
.slide-enter.slide-enter-active {
  opacity: 1;
  transition: opacity 600ms linear;
}
.slide-exit {
  opacity: 1;
}
.slide-exit.slide-exit-active {
  opacity: 0;
  transition: opacity 600ms linear;
}

/* Ripple Effect */
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, rgb(255, 255, 255) 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

.rotate {
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.rotate.rotate-90 {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
