/**
  Override Bootstrap Properties
 */

@import 'colors.scss';

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $smv-primary;
  background-color: $smv-primary;
}

.list-group-flush .list-group-item:first-child {
  border-top: 0;
}

/**
  END
 */

input.slide-input {
  border: none;
  box-sizing: border-box;
  padding: 3px 6px;
  border-bottom: 1px solid $smv-danger;

  width: 0px;
  outline: 0;
  opacity: 0;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out, opacity 0.4s;
}

input.slide-input:focus {
  width: 200px;
  opacity: 1;
  /*display: block;*/
}

.slide-border {
  border: none;
  box-sizing: border-box;
  border-radius: 0;
  border-bottom: 1px solid $smv-background-dark;
}

.slide-border:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  /*border-bottom: 2px solid $smv-primary;*/
  border-bottom-color: $smv-primary;
  /*-webkit-transition: border-color 0s ease-out 0.5s;*/
  transition: 2000ms all;
}

select:focus,
input:focus {
  outline: 0;
}
/* Question */

input.question,
select.question {
  font-size: 1em;
  border-radius: 0;
  padding: 6px 0;
  margin: 0;
  border: none;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  transition: padding-top 0.2s ease, margin-top 0.2s ease;
  overflow-x: hidden; /* Hack to make "rows" attribute apply in Firefox. */
}
/* Underline and Placeholder */

input.question ~ label,
select.question ~ label {
  display: block;
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 80%;
  border-top: 1px solid $smv-background-dark;
  -webkit-transition: width 0.4s ease;
  transition: width 0.4s ease;
  height: 0px;
}

input.question:focus ~ label,
select.question:focus ~ label {
  border-top: 1px solid $smv-primary;
  /*border-top-color: $smv-primary;*/
  width: 100%;
}

input.question:focus ~ label > span,
input.question:valid ~ label > span,
select.question:focus ~ label > span,
select.question:valid ~ label > span {
  text-transform: uppercase !important;
  top: -4em;
  font-size: 0.7em;
  color: $smv-background-dark;
}

input.question:valid ~ label,
select.question:valid ~ label {
  /*border-color: green;*/
}

input.question:invalid,
select.question:invalid {
  box-shadow: none;
}

input.question ~ label > span,
select.question ~ label > span {
  margin: 0;
  position: absolute;
  font-size: 1em;
  top: -2em;
  left: 0px;
  z-index: -1;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}

select:hover {
  cursor: pointer;
}

.custom-switch .custom-control-input.custom-control-label::after,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-switch .custom-control-label::before,
.custom-switch .custom-control-label::after {
  cursor: pointer;
}
